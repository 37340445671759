<template>
  <v-container fluid class="invite-link__container">
    <v-row justify="space-between" align="center">
      <v-col cols="auto" class="title-m-bold neutral-900--text">
        Пригласительные ссылки
      </v-col>
      <v-col cols="auto" class="col__input d-flex pa-0">
        <base-text-field
          v-model.trim="search"
          class="field-search"
          :prepend-inner-icon="'$iconify_search-outlined'"
          placeholder="Быстрый поиск"
          style="min-width: 225px"
          hide-details
          clear-icon="$iconify_ion-close-circle-outline"
          :prepend-inner-icon-color="
            this.$vuetify.theme.themes.light['neutral-500']
          "
          clearable
        />
        <v-btn class="btn-create" color="primary" @click="openCreateSidePanel">
          <v-icon class="icon" color="neutral-100" size="21">
            $iconify_feather-plus-circle
          </v-icon>
          <p class="body-m-semibold neutral-100--text mb-0">Создать</p>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <links-table :search="search" @edit="openEditSidePanel" />
      </v-col>
    </v-row>
    <actions-side-panel
      :edit.sync="actionsSidePanel.edit"
      :request-data.sync="actionsSidePanel.requestData"
      :show.sync="showSidePanel"
    />
  </v-container>
</template>

<script>
import ActionsSidePanel from "./ActionsSidePanel";
import LinksTable from "./Table.vue";

export default {
  components: {
    ActionsSidePanel,
    LinksTable,
  },
  data() {
    return {
      showSidePanel: false,
      actionsSidePanel: {
        edit: false,
        requestData: {},
        show: false,
      },
      search: "",
      standartSettings: {
        required_birthday: false,
        full_age_register: false,
        wallet_list: ['apple_and_google', 'pluscards', 'actonica_eda'],
      },
    };
  },
  computed: {
    programId() {
      return this.$store.getters.programId;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openCreateSidePanel() {
      this.actionsSidePanel.requestData = {
        name: "",
        invalid_action: "none",
        program_id: this.programId,
        link_type: "WEB",
        settings: this.standartSettings,
      };
      this.actionsSidePanel.edit = false;
      this.showSidePanel = true;
    },
    openEditSidePanel(payload) {
      if (payload.settings === null) {
        payload.settings = this.standartSettings;
      }
      this.actionsSidePanel.requestData = payload;
      this.actionsSidePanel.edit = true;
      this.showSidePanel = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-block__btn-action {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 53px !important;
  .icon {
    margin-right: 8px;
  }
}

.invite-link__container {
  .col__input {
    .field-search {
      width: 449px;
    }
    .btn-create {
      margin-left: 20px;
      .icon {
        margin-right: 8px;
      }
    }
  }
}
</style>
