<template>
  <div>
    <base-table
      class="invite-links-table"
      :headers="table.headers"
      :items="inviteLinks"
      :search="search"
      :loading="loadingTable"
      :options.sync="table.options"
      :storage-key="table.storageKey"
      :word-operations="table.wordOperations"
      @click:row="showQrDialog"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ item.id }}
      </template>
      <template v-slot:[`item.name`]="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:[`item.add_to_segment_id`]="{ item }">
        <v-tooltip
          v-if="getPickSegment(item.add_to_segment_id)"
          dark
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              v-bind="attrs"
              style="display: inline;"
              v-on="on"
              @click="clickedSegment(item.add_to_segment_id)"
            >
              <segment-chip
                class="item-segment"
                :name="getPickSegment(item.add_to_segment_id).name"
                :color="getPickSegment(item.add_to_segment_id).color"
              />
            </div>
          </template>
          {{ getPickSegment(item.add_to_segment_id).name }}
        </v-tooltip>
        <template v-else>
          -
        </template>
      </template>
      <template v-slot:[`item.expire_at`]="{ item }">
        <date-column :value="item.expire_at" />
      </template>
      <template v-slot:[`item.accounts_count`]="{ item }">
        {{ item.accounts_count || 0 }}
      </template>
      <template v-slot:[`item.active`]="{ item }">
        <v-switch
          v-model="item.active"
          class="item__switch-active"
          :loading="item.loadingChangeActive"
          :disabled="item.loadingChangeActive"
          inset
          hide-details
          @click.stop=""
          @change="changeActive(item, $event)"
        />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              small
              :loading="item.action"
              v-on="on"
            >
              <v-icon>$iconify_feather-more-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(menuItem, index) in itemActions"
              :key="index"
              @click="menuItem.action(item)"
            >
              <v-list-item-icon>
                <v-icon color="neutral-500">
                  {{ menuItem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-title
                :class=" {
                  'body-s-medium' : true,
                  'neutral-500--text':true,
                }"
              >
                {{ menuItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </base-table>

    <message-box

      v-model="showQRCode"
      scrollable
      :title="dialogQRData.name"
      max-width="80%"
    >
      <div
        ref="msgBox"
        class="message-box__qr"
      >
        <v-row
          class="_qr__copy-link"
          align="baseline"
          justify="center"
        >
          <v-col
            cols="6"
            class="text-truncate"
          >
            <a
              :href="dialogQRData.onelink"
              target="_blank"
              class="body-m-medium neutral-900--text mb-0"
            >
              {{ dialogQRData.onelink }}
            </a>
          </v-col>
          <v-col cols="auto">
            <v-btn
              icon
              color="primary"
              small
              @click="copyLinkInDialog"
            >
              <v-icon>
                $iconify_feather-copy
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div class="qr__slider">
          <v-slider
            v-model="dialogQRData.qrSize"
            min="100"
            max="500"
            label="Размер"
            step="20"
            :tick-labels="[100].concat(Array(19).fill()).concat(500)"
            thumb-label="always"
            hide-details
          >
            <!-- <template v-slot:prepend>
              <p
                style="margin-top: 2px"
                class="body-m-semibold mb-0"
              >
                100
              </p>
            </template>

            <template v-slot:append>
              <p
                style="margin-top: 2px"
                class="body-m-semibold mb-0"
              >
                500
              </p>
            </template> -->
          </v-slider>
        </div>
        <div class="qr__code">
          <qrcode-vue
            id="qrcode"
            :value="dialogQRData.onelink"
            :size="dialogQRData.qrSize ? dialogQRData.qrSize : 100"
          />
        </div>

        <div class="qr__controls">
          <v-btn
            class="btn-download"
            color="primary"
            @click="clickedDownloadQrCode"
          >
            <v-icon
              class="icon"
              color="neutral-100"
              size="21"
            >
              $iconify_feather-download
            </v-icon>
            <p class="body-m-semibold neutral-100--text mb-0">
              Скачать
            </p>
          </v-btn>
        </div>
      </div>
    </message-box>
  </div>
</template>

<script>
  // components
  import SegmentChip from '@/components/segment/SegmentChip'
  import DateColumn from '@/components/colums/DateColumn.vue'
  import MessageBox from '@/components/message-box/MessageBox.vue'
  import QrcodeVue from 'qrcode.vue'
  // other
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      SegmentChip,
      DateColumn,
      MessageBox,
      QrcodeVue,
    },
    props: {
      search: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        loadingTable: false,
        copyLinkComplite: false,
        showQRCode: false,
        qrSize: 0,
        dialogQRData: {},
        loadingsDelete: {},
        table: {
          storageKey: 'InvitationLinkTable',
          options: {
            page: 1,
            itemsPerPage: 25,
          },
          headers: [
            { text: 'ID', align: 'start', value: 'id' },
            { text: 'Наименование', align: 'start', value: 'name' },
            { text: 'Тип', align: 'start', value: 'type_str' },
            { text: 'Сегмент', align: 'start', value: 'add_to_segment_id' },
            { text: 'Кол-во клиентов', align: 'start', value: 'accounts_count' },
            { text: 'Дата истечения', align: 'start', value: 'expire_at' },
            { text: 'Активна', align: 'end', value: 'active', width: '35px' },
            { text: 'Действия', align: 'end', value: 'actions', width: '100px',  sortable: false },
          ],
          wordOperations: ['ссылка', 'ссылки', 'ссылок'],
        },
      }
    },
    computed: {
      ...mapGetters({
        pickSegments: 'crm/segment/pickSegments',
        programId: 'programId',
      }),
      inviteLinks () {
        const typeMap = {
          MOBILE: 'Мобильное приложение',
          WEB: 'Браузер',
        }
        return this.$store.getters['inviteLink/inviteLink/links'].map((item) => {
          item.type_str = typeMap[item.link_type]
          return item
        })
      },
      itemActions () {
        return [
          {
            icon: '$iconify_feather-copy',
            title: 'Скопировать',
            tooltip: 'Скопировать ссылку',
            action: this.copyLink,
            show: true,
          },
          {
            icon: '$iconify_ion-qr-code-outline',
            title: 'QR-код',
            action: this.showQrDialog,
            show: true,
          },
          {
            icon: '$iconify_feather-edit',
            title: 'Редактировать',
            action: (item) => {
              this.$emit('edit', Object.assign({}, item))
            },
            show: true,
          },
          {
            icon: '$iconify_feather-trash',
            title: 'Удалить',
            action: async (item) => {
              await this.$store.dispatch('inviteLink/inviteLink/delete', item.id)
              this.$notify({
                title: 'Пригласительная ссылка',
                text: 'Пригласительная ссылка удалена',
                type: 'success',
              })
            },
            show: true,
          },
        ].filter(x => x.show)
      },
    },
    watch: {
      programId (v) {
        this.loadPickSegments()
        this.fetchData(v)
      },
    },
    async mounted () {
      this.loadPickSegments()
      await this.fetchData()
    },
    methods: {
      ...mapActions({
        getSegmentPickList: 'crm/segment/getPickList',
      }),
      getPickSegment (id) {
        return this.pickSegments.find(s => s.id === id)
      },
      async fetchData (programId) {
        try {
          this.loadingTable = true
          await this.$store.dispatch('inviteLink/inviteLink/list', programId || this.programId)
        } catch (error) {
          this.loadingTable = false
        } finally {
          this.loadingTable = false
        }
      },
      async changeActive (link, active) {
        await this.$store.dispatch('inviteLink/inviteLink/changeActive', { linkId: link.id, active })
        if (!link.active) {
          this.$notify({
            title: 'Пригласительная ссылка',
            text: 'Пригласительная ссылка выключена',
            type: 'success',
          })
        } else {
          this.$notify({
            title: 'Пригласительная ссылка',
            text: 'Пригласительная ссылка включена',
            type: 'success',
          })
        }
      },
      clickedSegment (id) {
        this.$router.push({ name: 'ProgramAccounts', query: { segmentId: id } })
      },
      editInviteLink (link) {
        this.$router.push({ name: 'PromoEdit', params: { id: link.id } })
      },
      async loadPickSegments (programId) {
        try {
          await this.getSegmentPickList(programId || this.programId)
        } catch (e) {
          console.error(e)
        }
      },
      clickedDownloadQrCode () {
        const link = document.createElement('a')
        const qrCvs = document.getElementById('qrcode').querySelector('canvas')
        link.download = 'qrcode_' + this.dialogQRData.name + '.png'
        link.href = qrCvs.toDataURL()
        link.click()
      },
      async copyLinkInDialog () {
        try {
          await this.$copyText(this.dialogQRData.onelink, this.$refs.msgBox)
          this.$notify({
            title: 'Генератор ссылки',
            text: 'Ссылка успешно скопирована!',
            type: 'success',
          })
        } catch (error) {
          console.error(error)
        }
      },
      async copyLink (item) {
        try {
          await this.$copyText(item.onelink)
          this.$notify({
            title: 'Генератор ссылки',
            text: 'Ссылка успешно скопирована!',
            type: 'success',
          })
        } catch (error) {
          console.error(error)
        }
      },
      showQrDialog (item) {
        this.showQRCode = true
        this.dialogQRData = Object.assign({}, item)
      },
      async deleteInviteLink (link) {
        this.loadingsDelete[link.id] = true
        await this.$store.dispatch('inviteLink/inviteLink/delete', link.id)
        this.loadingsDelete[link.id] = false
        delete this.loadingsDelete[link.id]
        this.$notify({
          title: 'Пригласительная ссылка',
          text: 'Пригласительная ссылка удалена',
          type: 'success',
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '@/styles/vuetify-preset-plus/light_theme/_variables.sass';

.invite-links-table {
  & ::v-deep {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }
  .item__switch-active {
    display: inline-flex;
  }
  .item-segment {
    display: inline;
    cursor: pointer;
  }
  .item__action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 40px;
    .action-btn-edit {
      margin-right: 12px;
    }
    .action-btn-edit,
    .action-btn-delete {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      min-width: 0;
      .icon {
        color: $neutral-600;
      }
    }
  }
}

.message-box__qr {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .qr__copy-link {
    margin-top: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    a {
      max-width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
    }
  }
  .qr__slider {
    margin-top: 14px;
  }
  .qr__code {
    margin-top: 14px;
    text-align: center;
  }
  .qr__controls {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    .btn-download {
      .icon {
        margin-right: 8px;
      }
    }
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}
</style>
