<template>
  <base-side-panel
    v-model="internalActive"
    class="side-panel__invitation-link"
    :width="483"
  >
    <div class="side-panel__header">
      <p class="title-m-bold neutral-900--text mb-0">
        Пригласительная ссылка
      </p>
    </div>
    <div class="side-panel__body">
      <v-form
        v-if="show"
        v-model="validForm"
      >
        <field-block
          title="Название ссылки"
          description="Введите уникальное название ссылки для дальнейшего отслеживания трафика клиентов."
        >
          <template v-slot:input>
            <base-text-field
              v-model="requestData.name"
              :rules="[
                v => (!!v || typeof v !== 'string') || 'Обязательно для заполнения',
                v => String(v).replace(/(^\s*|\s*$)/, '').length <= 255 || 'Не должно превышать 255 символов'
              ]"
              maxlength="255"
              error-style="custom"
              validation-placement="left"
              placeholder="Введите название ссылки"
            />
          </template>
        </field-block>
        <field-block
          title="Тип ссылки"
          description="Выберите тип пригласительной ссылки."
        >
          <template v-slot:input>
            <v-select
              v-model="requestData.link_type"
              class=""
              :items="linkTypeList"
              item-value="id"
              item-text="name"
              outlined
              hide-details
            />
          </template>
        </field-block>
        <field-block
          title="Сегмент"
          description="Выберите сегмент к которому будет относится клиент выпустивший карту по данной ссылке."
        >
          <template v-slot:input>
            <v-select
              v-model="requestData.add_to_segment_id"
              class="field__filter"
              :items="pickSegments"
              item-text="name"
              item-value="id"
              placeholder="Выберите сегмент"
              outlined
              :loading="loadingSegmenPickList"
              clearable
              hide-details
            >
              <template v-slot:selection="{item}">
                <segment-chip
                  :name="item.name"
                  :color="item.color"
                  :is_public="item.is_public"
                />
              </template>
              <template v-slot:item="{item}">
                <segment-chip
                  :name="item.name"
                  :color="item.color"
                  :is_public="item.is_public"
                  max-width="380px"
                />
              </template>
            </v-select>
          </template>
        </field-block>
        <field-block
          title="Дата истечения"
          description="Если требуется сделать временную ссылку - выберите дату истечения пригласительной ссылки."
        >
          <template v-slot:input>
            <base-datetime-picker-field
              v-model="requestData.expire_at"
              :with-time="false"
              placeholder="Введите дату истечения ссылки"
              clearable
            />
          </template>
        </field-block>

        <field-block v-if="this.requestData.link_type == 'WEB'"
          title="Кошельки"
          description="Настройте выбор приложений для сохранения карты клиента."
        >
        
        <template v-slot:input>
          <v-select
          v-model="requestData.settings.wallet_list"
          :items="walletListItems"
          item-text="name"
          item-value="value"
          multiple          
          outlined
          chips
          :rules="[
                v => ( v.length > 0) || 'Обязательно для заполнения',
          ]"
          ></v-select>
        </template>

        </field-block>

        <field-block v-if="this.requestData.link_type == 'WEB'"
          title="Дата Рождения"
          description="Настройте правила заполнение даты рождения при регистрации клиентом."
        >
          <template v-slot:input>
          <v-switch
            label="Обязательная дата рождения"
            v-model="requestData.settings.required_birthday"
          ></v-switch>
          <v-switch
            label="Запрет регистрации не совершеннолетних"
            v-model="requestData.settings.full_age_register"
          ></v-switch>
          </template>
        </field-block>

        <field-block
          v-if="requestData.link_type == 'MOBILE'"
          title="Действие"
          description="Выберите как будет обрабатываться переход по не действительной ссылке"
        >
          <template v-slot:input>
            <v-row>
              <v-col>
                <v-radio-group
                  v-model="requestData.invalid_action"
                  class="mt-0"
                  hide-details
                  :rules="[
                    v => Boolean(!!v && v.length) || 'Выберите действие'
                  ]"
                  row
                >
                  <v-radio
                    v-for="item in emitModeList"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="emitModeObject && emitModeObject.comment">
              <v-col cols="auto">
                <v-icon>$iconify_feather-info</v-icon>
              </v-col>
              <v-col class="body-m-regular neutral-600--text">
                {{ emitModeObject.comment }}
              </v-col>
            </v-row>
          </template>
        </field-block>
        <v-row
          class="row-controls"
          no-gutters
        >
          <v-col class="pa-0 d-flex align-center justify-space-between">
            <v-btn
              class="btn-create"
              color="primary"
              :loading="loadingCreate"
              :disabled="!validForm"
              @click="createInviteLink()"
            >
              <v-icon
                class="icon"
                color="neutral-100"
                size="21"
              >
                $iconify_{{ edit ? 'ion-checkmark-circle-outline' : 'feather-plus-circle' }}
              </v-icon>
              <p class="body-m-semibold neutral-100--text mb-0">
                {{ edit ? 'Сохранить' : 'Создать' }}
              </p>
            </v-btn>
            <v-btn
              v-if="edit"
              class="btn-delete"
              color="error"
              :loading="loadingDelete"
              text
              @click="deleteInviteLink()"
            >
              <iconify-icon
                icon="feather-trash"
                class="icon icon-check error--text"
                width="21"
              />
              <p class="body-m-semibold mb-0 error--text">
                Удалить ссылку
              </p>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </base-side-panel>
</template>

<script>
  // components
  import FieldBlock from '@/components/side-panel/components/FieldBlock.vue'
  import SegmentChip from '@/components/segment/SegmentChip'
  // other
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      FieldBlock,
      SegmentChip,
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      edit: {
        type: Boolean,
        default: false,
      },
      requestData: {
        type: Object,
        default: () => {
          return {
            name: '',
            add_to_segment_id: null,
            program_id: null,
            link_type: 'WEB',
            settings: {
              required_birthday: false,
              full_age_register: false,
              wallet_list: []
            },
          }
        },
      },
    },
    data () {
      return {
        internalActive: false,
        loadingSegmenPickList: false,
        validForm: false,
        loadingCreate: false,
        loadingDelete: false,
        walletListItems: [
          {name: "Apple and Google Wallet", value: "apple_and_google"},
          {name: "Pluscards", value: "pluscards"},
          {name: "Приложение eDA", value: "actonica_eda"},
        ],
        linkTypeList: [
          { id: 'MOBILE', name: 'Мобильное приложение' },
          { id: 'WEB', name: 'Браузер' },
        ],
        emitModeList: [
          {
            id: 'dialog',
            text: 'Диалог',
            comment: 'Отображается диалог с предложением обычного выпуска карты либо отмены',
          },
          {
            id: 'notify',
            text: 'Предупреждение ',
            comment: 'Отображается диалог с предупреждением',
          },
          {
            id: 'notify_emit',
            text: 'Предупреждение и обычный выпуск',
            comment: 'Отображается диалог с предупрждением и последующим обычным выпуском карты',
          },
          {
            id: 'emit',
            text: 'Выпуск',
            comment: 'Обычный выпуск карты без предупреждения',
          },
          {
            id: 'none',
            text: 'Без действий',
            comment: 'Ни каких действий не производится',
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        pickSegments: 'crm/segment/pickSegments',
        programId: 'programId',
      }),
      emitModeObject () {
        return this.$_.findWhere(this.emitModeList, { id: this.requestData.invalid_action })
        // return this.$_.findWhere(this.requestData.invalid_action, { id: this.emitMode })
      },
      // expireDateRules () {
      //   return [
      //     v => {
      //       if (
      //         !v ||
      //         !v.length ||
      //         this.$moment(v, this.$config.date.DATE_FORMAT) === 'Invalid Date'
      //       ) {
      //         return true
      //       } else {
      //         return (
      //           this.$moment(v, this.$config.date.DATE_FORMAT).diff(this.$moment(), 'days') <= 0
      //         ) || 'Недопустимая дата'
      //       }
      //     },
      //   ]
      // },
    },
    watch: {
      show (v) {
        this.internalActive = v
      },
      internalActive (v) {
        this.$emit('update:show', v)
      },
      programId (v) {
        if (!this.edit) {
          this.requestData.program_id = v
          this.requestData.add_to_segment_id = null
        }
        this.loadPickSegments()
      },
    },
    mounted () {
      this.loadPickSegments()
      if (!this.edit) {
        this.requestData.program_id = this.programId
        this.requestData.link_type = 'WEB'
      }
    },
    methods: {
      ...mapActions({
        getSegmentPickList: 'crm/segment/getPickList',
      }),

      async loadPickSegments () {
        try {
          this.loadingSegmenPickList = true
          await this.getSegmentPickList(this.requestData.program_id || this.programId)
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingSegmenPickList = false
        }
      },
      async createInviteLink () {
        try {
          if (this.validForm) {
            this.loadingCreate = true
            if (this.edit) {
              await this.$store.dispatch('inviteLink/inviteLink/update', { linkId: this.requestData.id, payload: this.requestData })
            } else {
              await this.$store.dispatch('inviteLink/inviteLink/create', this.requestData)
            }
            this.loadingCreate = false
            this.$emit('update:requestData', {})
            this.$emit('update:edit', false)
            this.internalActive = false
          }
        } finally {
          this.loadingCreate = false
        }
      },
      async deleteInviteLink () {
        try {
          this.loadingDelete = true
          await this.$store.dispatch('inviteLink/inviteLink/delete', this.requestData.id)
          this.loadingDelete = false
          this.$emit('update:requestData', {})
          this.$emit('update:edit', false)
          this.internalActive = false
        } finally {
          this.loadingDelete = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.side-panel__invitation-link {
  .side-panel__header {
    margin-top: 50px;
    padding: 0 34px 34px 34px;
  }
  .side-panel__body {
    padding: 0 34px 0 34px;
    .row-controls {
      margin-bottom: 34px;
      .btn-create {
        .icon {
          margin-right: 8px;
        }
      }
      .btn-delete {
        padding: 12px 4px 12px 2px!important;
        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
