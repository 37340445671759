var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-table',{staticClass:"invite-links-table",attrs:{"headers":_vm.table.headers,"items":_vm.inviteLinks,"search":_vm.search,"loading":_vm.loadingTable,"options":_vm.table.options,"storage-key":_vm.table.storageKey,"word-operations":_vm.table.wordOperations},on:{"update:options":function($event){return _vm.$set(_vm.table, "options", $event)},"click:row":_vm.showQrDialog},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.add_to_segment_id",fn:function(ref){
var item = ref.item;
return [(_vm.getPickSegment(item.add_to_segment_id))?_c('v-tooltip',{attrs:{"dark":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticStyle:{"display":"inline"},on:{"click":function($event){return _vm.clickedSegment(item.add_to_segment_id)}}},'div',attrs,false),on),[_c('segment-chip',{staticClass:"item-segment",attrs:{"name":_vm.getPickSegment(item.add_to_segment_id).name,"color":_vm.getPickSegment(item.add_to_segment_id).color}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.getPickSegment(item.add_to_segment_id).name)+" ")]):[_vm._v(" - ")]]}},{key:"item.expire_at",fn:function(ref){
var item = ref.item;
return [_c('date-column',{attrs:{"value":item.expire_at}})]}},{key:"item.accounts_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.accounts_count || 0)+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"item__switch-active",attrs:{"loading":item.loadingChangeActive,"disabled":item.loadingChangeActive,"inset":"","hide-details":""},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.changeActive(item, $event)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","loading":item.action}},on),[_c('v-icon',[_vm._v("$iconify_feather-more-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.itemActions),function(menuItem,index){return _c('v-list-item',{key:index,on:{"click":function($event){return menuItem.action(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"neutral-500"}},[_vm._v(" "+_vm._s(menuItem.icon)+" ")])],1),_c('v-list-item-title',{class:{
                'body-s-medium' : true,
                'neutral-500--text':true,
              }},[_vm._v(" "+_vm._s(menuItem.title)+" ")])],1)}),1)],1)]}}],null,true)}),_c('message-box',{attrs:{"scrollable":"","title":_vm.dialogQRData.name,"max-width":"80%"},model:{value:(_vm.showQRCode),callback:function ($$v) {_vm.showQRCode=$$v},expression:"showQRCode"}},[_c('div',{ref:"msgBox",staticClass:"message-box__qr"},[_c('v-row',{staticClass:"_qr__copy-link",attrs:{"align":"baseline","justify":"center"}},[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"6"}},[_c('a',{staticClass:"body-m-medium neutral-900--text mb-0",attrs:{"href":_vm.dialogQRData.onelink,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.dialogQRData.onelink)+" ")])]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","color":"primary","small":""},on:{"click":_vm.copyLinkInDialog}},[_c('v-icon',[_vm._v(" $iconify_feather-copy ")])],1)],1)],1),_c('div',{staticClass:"qr__slider"},[_c('v-slider',{attrs:{"min":"100","max":"500","label":"Размер","step":"20","tick-labels":[100].concat(Array(19).fill()).concat(500),"thumb-label":"always","hide-details":""},model:{value:(_vm.dialogQRData.qrSize),callback:function ($$v) {_vm.$set(_vm.dialogQRData, "qrSize", $$v)},expression:"dialogQRData.qrSize"}})],1),_c('div',{staticClass:"qr__code"},[_c('qrcode-vue',{attrs:{"id":"qrcode","value":_vm.dialogQRData.onelink,"size":_vm.dialogQRData.qrSize ? _vm.dialogQRData.qrSize : 100}})],1),_c('div',{staticClass:"qr__controls"},[_c('v-btn',{staticClass:"btn-download",attrs:{"color":"primary"},on:{"click":_vm.clickedDownloadQrCode}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"neutral-100","size":"21"}},[_vm._v(" $iconify_feather-download ")]),_c('p',{staticClass:"body-m-semibold neutral-100--text mb-0"},[_vm._v(" Скачать ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }