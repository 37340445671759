var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-side-panel',{staticClass:"side-panel__invitation-link",attrs:{"width":483},model:{value:(_vm.internalActive),callback:function ($$v) {_vm.internalActive=$$v},expression:"internalActive"}},[_c('div',{staticClass:"side-panel__header"},[_c('p',{staticClass:"title-m-bold neutral-900--text mb-0"},[_vm._v(" Пригласительная ссылка ")])]),_c('div',{staticClass:"side-panel__body"},[(_vm.show)?_c('v-form',{model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('field-block',{attrs:{"title":"Название ссылки","description":"Введите уникальное название ссылки для дальнейшего отслеживания трафика клиентов."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('base-text-field',{attrs:{"rules":[
              function (v) { return (!!v || typeof v !== 'string') || 'Обязательно для заполнения'; },
              function (v) { return String(v).replace(/(^\s*|\s*$)/, '').length <= 255 || 'Не должно превышать 255 символов'; }
            ],"maxlength":"255","error-style":"custom","validation-placement":"left","placeholder":"Введите название ссылки"},model:{value:(_vm.requestData.name),callback:function ($$v) {_vm.$set(_vm.requestData, "name", $$v)},expression:"requestData.name"}})]},proxy:true}],null,false,2625705996)}),_c('field-block',{attrs:{"title":"Тип ссылки","description":"Выберите тип пригласительной ссылки."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.linkTypeList,"item-value":"id","item-text":"name","outlined":"","hide-details":""},model:{value:(_vm.requestData.link_type),callback:function ($$v) {_vm.$set(_vm.requestData, "link_type", $$v)},expression:"requestData.link_type"}})]},proxy:true}],null,false,2499221626)}),_c('field-block',{attrs:{"title":"Сегмент","description":"Выберите сегмент к которому будет относится клиент выпустивший карту по данной ссылке."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{staticClass:"field__filter",attrs:{"items":_vm.pickSegments,"item-text":"name","item-value":"id","placeholder":"Выберите сегмент","outlined":"","loading":_vm.loadingSegmenPickList,"clearable":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('segment-chip',{attrs:{"name":item.name,"color":item.color,"is_public":item.is_public}})]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('segment-chip',{attrs:{"name":item.name,"color":item.color,"is_public":item.is_public,"max-width":"380px"}})]}}],null,false,784105030),model:{value:(_vm.requestData.add_to_segment_id),callback:function ($$v) {_vm.$set(_vm.requestData, "add_to_segment_id", $$v)},expression:"requestData.add_to_segment_id"}})]},proxy:true}],null,false,1299932249)}),_c('field-block',{attrs:{"title":"Дата истечения","description":"Если требуется сделать временную ссылку - выберите дату истечения пригласительной ссылки."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('base-datetime-picker-field',{attrs:{"with-time":false,"placeholder":"Введите дату истечения ссылки","clearable":""},model:{value:(_vm.requestData.expire_at),callback:function ($$v) {_vm.$set(_vm.requestData, "expire_at", $$v)},expression:"requestData.expire_at"}})]},proxy:true}],null,false,2125542777)}),(this.requestData.link_type == 'WEB')?_c('field-block',{attrs:{"title":"Кошельки","description":"Настройте выбор приложений для сохранения карты клиента."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.walletListItems,"item-text":"name","item-value":"value","multiple":"","outlined":"","chips":"","rules":[
              function (v) { return ( v.length > 0) || 'Обязательно для заполнения'; } ]},model:{value:(_vm.requestData.settings.wallet_list),callback:function ($$v) {_vm.$set(_vm.requestData.settings, "wallet_list", $$v)},expression:"requestData.settings.wallet_list"}})]},proxy:true}],null,false,3426221215)}):_vm._e(),(this.requestData.link_type == 'WEB')?_c('field-block',{attrs:{"title":"Дата Рождения","description":"Настройте правила заполнение даты рождения при регистрации клиентом."},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-switch',{attrs:{"label":"Обязательная дата рождения"},model:{value:(_vm.requestData.settings.required_birthday),callback:function ($$v) {_vm.$set(_vm.requestData.settings, "required_birthday", $$v)},expression:"requestData.settings.required_birthday"}}),_c('v-switch',{attrs:{"label":"Запрет регистрации не совершеннолетних"},model:{value:(_vm.requestData.settings.full_age_register),callback:function ($$v) {_vm.$set(_vm.requestData.settings, "full_age_register", $$v)},expression:"requestData.settings.full_age_register"}})]},proxy:true}],null,false,66193426)}):_vm._e(),(_vm.requestData.link_type == 'MOBILE')?_c('field-block',{attrs:{"title":"Действие","description":"Выберите как будет обрабатываться переход по не действительной ссылке"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-radio-group',{staticClass:"mt-0",attrs:{"hide-details":"","rules":[
                  function (v) { return Boolean(!!v && v.length) || 'Выберите действие'; }
                ],"row":""},model:{value:(_vm.requestData.invalid_action),callback:function ($$v) {_vm.$set(_vm.requestData, "invalid_action", $$v)},expression:"requestData.invalid_action"}},_vm._l((_vm.emitModeList),function(item){return _c('v-radio',{key:item.id,attrs:{"label":item.text,"value":item.id}})}),1)],1)],1),(_vm.emitModeObject && _vm.emitModeObject.comment)?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-icon',[_vm._v("$iconify_feather-info")])],1),_c('v-col',{staticClass:"body-m-regular neutral-600--text"},[_vm._v(" "+_vm._s(_vm.emitModeObject.comment)+" ")])],1):_vm._e()]},proxy:true}],null,false,655054352)}):_vm._e(),_c('v-row',{staticClass:"row-controls",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 d-flex align-center justify-space-between"},[_c('v-btn',{staticClass:"btn-create",attrs:{"color":"primary","loading":_vm.loadingCreate,"disabled":!_vm.validForm},on:{"click":function($event){return _vm.createInviteLink()}}},[_c('v-icon',{staticClass:"icon",attrs:{"color":"neutral-100","size":"21"}},[_vm._v(" $iconify_"+_vm._s(_vm.edit ? 'ion-checkmark-circle-outline' : 'feather-plus-circle')+" ")]),_c('p',{staticClass:"body-m-semibold neutral-100--text mb-0"},[_vm._v(" "+_vm._s(_vm.edit ? 'Сохранить' : 'Создать')+" ")])],1),(_vm.edit)?_c('v-btn',{staticClass:"btn-delete",attrs:{"color":"error","loading":_vm.loadingDelete,"text":""},on:{"click":function($event){return _vm.deleteInviteLink()}}},[_c('iconify-icon',{staticClass:"icon icon-check error--text",attrs:{"icon":"feather-trash","width":"21"}}),_c('p',{staticClass:"body-m-semibold mb-0 error--text"},[_vm._v(" Удалить ссылку ")])],1):_vm._e()],1)],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }